import CryptoJS from "crypto-js";

const key = "477c8d07b758482b93117808baf4d2c2";
const iv = "cd6ebd25e4914bf1";

export const encrypt = (plainText) => {
  const encrypted = CryptoJS.AES.encrypt(
    plainText,
    CryptoJS.enc.Hex.parse(key),
    {
      iv: CryptoJS.enc.Hex.parse(iv),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  );

  return encrypted.toString();
};

export const decrypt = (cipherText) => {
  const decrypted = CryptoJS.AES.decrypt(
    cipherText,
    CryptoJS.enc.Hex.parse(key),
    {
      iv: CryptoJS.enc.Hex.parse(iv),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }
  );

  return decrypted.toString(CryptoJS.enc.Utf8);
};

// AES Encryption Service
export const encryptionService = {
  AES_Web_App_Key: "349cb1a784539591024ea97f8c549092",

  // AES Encrypt Function (WebApp)
  async encryptAES(plainText) {
    const key = this.AES_Web_App_Key;
    const keyBytes = new TextEncoder().encode(key);

    // Generate AES key from the key bytes
    const aesKey = await crypto.subtle.importKey(
      "raw",
      keyBytes.slice(0, 16),
      { name: "AES-CBC" },
      false,
      ["encrypt"]
    );

    // Use the same key bytes as IV for CBC mode
    const iv = keyBytes.slice(0, 16);

    // Encode the plain text
    const data = new TextEncoder().encode(plainText);

    // Perform AES encryption
    const encryptedData = await crypto.subtle.encrypt(
      { name: "AES-CBC", iv: iv },
      aesKey,
      data
    );

    // Convert encrypted buffer to base64
    return this.bufferToBase64(new Uint8Array(encryptedData));
  },

  // AES Decrypt Function (WebApp)
  async decryptAES(encryptedText) {
    const key = this.AES_Web_App_Key;
    const keyBytes = new TextEncoder().encode(key);

    // Generate AES key from the key bytes
    const aesKey = await crypto.subtle.importKey(
      "raw",
      keyBytes.slice(0, 16),
      { name: "AES-CBC" },
      false,
      ["decrypt"]
    );

    // Use the same key bytes as IV for CBC mode
    const iv = keyBytes.slice(0, 16);

    // Decode base64 encrypted data
    const encryptedData = this.base64ToBuffer(encryptedText);

    // Perform AES decryption
    const decryptedData = await crypto.subtle.decrypt(
      { name: "AES-CBC", iv: iv },
      aesKey,
      encryptedData
    );

    // Convert decrypted buffer to string
    return new TextDecoder().decode(decryptedData);
  },

  // Helper to convert buffer to Base64 string
  // bufferToBase64(buffer) {
  //   return btoa(String.fromCharCode(...buffer));
  // },

  bufferToBase64(buffer) {
    let binary = "";
    const len = buffer.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(buffer[i]);
    }
    return btoa(binary);
  },
  // Helper to convert Base64 string to buffer
  base64ToBuffer(base64) {
    const binaryString = atob(base64);
    const len = binaryString.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
  },
};
