import React, { useState, useRef, useEffect, useCallback } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import AddEditPeoples from "../components/add_edit_peoples";
import { Dialog } from "primereact/dialog";
import appUrl from "../../../../constants/appUrl";
import axios from "axios";
import { confirmDialog } from "primereact/confirmdialog";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import Loader from "../../../components/loader";
import {
  formatDate,
  renderTextWithTooltip,
  formatTo12Hour,
  emailsRenderTextWithTooltip,
} from "../../../components/helper";
import editIcon from "../../../../../assets/icons/bx_edit.png";
import { Tag } from "primereact/tag";
import * as XLSX from "xlsx";
import deleteIcon from "../../../../../assets/icons/trash.png";
import CustomImagePreview from "../../../components/custom_imagepreview";
import { Menu } from "primereact/menu";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Button } from "primereact/button";
import apiClient from "../../../../services/axios_api";

const People = () => {
  const [dialog, setDialog] = useState();
  const [peopleList, setPeopleList] = useState();
  const [res, setRes] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rowselect, setRowselect] = useState(null);
  const [editable, setEditable] = useState(false);
  const [expandedRows, setExpandedRows] = useState(null);
  const [menuVisible, setMenuVisible] = useState(true);
  const menuRef = useRef(null);
  const [uploadDialog, setUploadDialog] = useState(false);
  const [fileName, setFileName] = useState(null);

  const dt = useRef(null);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };
    _filters["global"].value = value;
    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const GetPeoplesList = async () => {
    try {
      setLoading(true);
      const response = await apiClient.get("/People/all");
      setPeopleList(response.data?.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const onHide = () => {
    setDialog(false);
  };
  const editAction = (rowData) => {
    setEditable(true);
    setRowselect(rowData);
    setDialog(true);
  };
  const actionTemplate = (rowData) => (
    <div className="action-buttons">
      <Button
        className="custom-btn-edit"
        onClick={() => {
          editAction(rowData);
        }}
      >
        <img
          src={editIcon}
          alt="Edit"
          style={{ width: "20px", height: "20px" }}
        />
      </Button>
      <Button
        className="custom-btn-delete"
        onClick={() => {
          confirm(rowData);
        }}
      >
        <img
          src={deleteIcon}
          alt="Delete"
          style={{ width: "20px", height: "20px" }}
        />
      </Button>
    </div>
  );
  const confirm = (rowData) => {
    confirmDialog({
      header: <div className="custom-header">Delete People</div>,
      icon: "pi pi-info-circle",
      message: <strong>Are you sure you want to delete this People?</strong>,
      accept: () => accept(rowData),
      reject: () => reject(rowData),
      acceptClassName: "custom-btn-red",
      rejectClassName: "custom-white-red",
      className: "center-buttons no-close-button",
    });
  };

  const accept = async (rowData) => {
    try {
      const response = await apiClient.post("/People/delete", {
        id: rowData?.id,
      });
      setRes(response.data);
      toast.success("Deleted Successfully");
    } catch (error) {
      console.error("Error deleting data:", error);
      toast.error("Failed to delete");
    }
  };

  const reject = (rowData) => {
    return;
  };

  const exportCSV = () => {
    const exportData = peopleList.map((item) => ({
      Name: item.name,
      "Mobile Number": item.phoneNumber,
      CNIC: item.cnicNumber,
      Email: item.email,
      Address: item.address,
      Group: item.groupName,
      "Modified Date": formatTo12Hour(item.modifiedDate),
    }));

    // Create a new workbook and a worksheet
    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(exportData);

    // Define column widths (all set to 120 pixels)
    worksheet["!cols"] = [
      { wpx: 120 },
      { wpx: 120 },
      { wpx: 120 },
      { wpx: 120 },
      { wpx: 120 },
      { wpx: 120 },
      { wpx: 120 },
    ];

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "People Data");

    // Write the workbook to file
    XLSX.writeFile(workbook, "people_data.xlsx");
  };

  useEffect(() => {
    GetPeoplesList();
  }, [res]);

  const columnsData = [
    {
      field: "name",
      header: "Name",
      body: (rowData) => renderTextWithTooltip(rowData, "name", "bottom"),
    },
    {
      field: "phoneNumber",
      header: "Mobile Number",
    },
    {
      field: "cnicNumber",
      header: "CNIC",
    },
    {
      field: "email",
      header: "Email",
      body: (rowData) =>
        emailsRenderTextWithTooltip(rowData, "email", "bottom"),
    },
    {
      field: "address",
      header: "Address",
      body: (rowData) => renderTextWithTooltip(rowData, "address", "bottom"),
    },
    {
      field: "groupName",
      header: "Group",
      body: (rowData) => renderTextWithTooltip(rowData, "groupName", "bottom"),
    },
    {
      field: "modifiedDate",
      header: "Date",
      body: (rowData) => formatDate(rowData.modifiedDate),
    },
    {
      field: "profileImageUrl",
      header: "Profile Image",
      body: (rowData) => (
        <CustomImagePreview
          src={`${appUrl.baseUrl}${rowData.profileImageUrl}`}
          alt=""
        />
      ),
    },
    {
      header: "Action",
      body: actionTemplate,
    },
  ];

  const renderAccessControls = useCallback((rowData) => {
    return (
      <DataTable value={rowData?.personAccessControls} scrollable>
        <Column field="gate.number" header="Gate Number" />
        <Column field="gate.description" header="Gate Description" />
        {/* <Column
          field="supportsOfflineMode"
          header="Gate Status"
          body={(rowData) =>
            rowData?.gate?.supportsOfflineMode ? (
              <Tag severity="warning" value="Offline" />
            ) : (
              <Tag severity="success" value="Online" />
            )
          }
        /> */}
        <Column
          field="modifiedDate"
          header="Date"
          body={(data) => formatDate(data.modifiedDate)}
        />
        <Column
          field="expiry"
          header="Expiry"
          body={(data) => formatDate(data.expiry)}
        />
      </DataTable>
    );
  }, []);

  const rowExpansionTemplate = (data) => {
    return <div>{renderAccessControls(data)}</div>;
  };

  function downloadExcelFile() {
    fetch(`${appUrl.baseUrl}/api/People/DownloadTemplate`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "CustomerBulkUpload.xlsx");
        document.body.appendChild(link);
        link.click();
        link.remove();
      })
      .catch((error) => {
        console.error("There was an error downloading the file:", error);
      });
  }

  const menuItems = [
    {
      label: "Download Excel Template",
      icon: "pi pi-download",
      command: downloadExcelFile,
    },
    {
      label: "Upload Bulk Excel",
      icon: "pi pi-upload",
      command: () => {
        setUploadDialog(true);
      },
    },
  ];

  const handleBulkUpload = (event) => {
    const file = event.target.files[0];
    formik.setFieldValue("bulkFile", file);
    setFileName(file ? file.name : null);
  };

  const validationSchema = Yup.object().shape({
    bulkFile: Yup.mixed().required("Please upload a file before submitting."),
  });

  const formik = useFormik({
    initialValues: {
      bulkFile: null,
    },
    validationSchema,
    onSubmit: async (values) => {
      const formData = new FormData();
      formData.append("file", values.bulkFile);
      setLoading(true);

      try {
        const response = await axios.post(
          `${appUrl.baseUrl}/api/People/UploadExcel`,
          formData,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        toast.success("File uploaded successfully!");
        setRes((prevRes) => !prevRes);

        setLoading(false);
        setUploadDialog(false);
        setFileName(null);
        formik.resetForm();
      } catch (error) {
        console.error("Error uploading file:", error);

        // Reset the touched state to allow re-uploading the same file
        formik.setTouched({ bulkFile: false });

        if (
          error.response &&
          error.response.status === 400 &&
          error.response.data
        ) {
          toast.error(error.response.data);
          formik.setFieldValue("bulkFile", values.bulkFile);
        } else {
          toast.error("Failed to upload file. Please try again.");
        }

        // Close the dialog on any error
        setUploadDialog(false);
        formik.resetForm();
        setFileName(null);
        setLoading(false);
      }
    },
  });

  const handleCloseDialog = () => {
    setUploadDialog(false);
    setFileName(null);
    formik.resetForm();
  };

  return (
    <>
      <Dialog
        visible={dialog}
        onHide={onHide}
        header={editable ? "Edit People" : "Add People"}
        className="dialog-size"
      >
        <AddEditPeoples
          dialog={dialog}
          editable={editable}
          rowData={rowselect}
          setDialog={setDialog}
          onHide={onHide}
          setRes={setRes}
        />
      </Dialog>

      <Dialog
        header="Upload Bulk People"
        visible={uploadDialog}
        className="dialog-size"
        onHide={handleCloseDialog}
      >
        <div className="field col-12 md:col-12">
          <label>
            Upload Bulk People{" "}
            <span className="Staric-Custom text-danger"> *</span>
          </label>

          <div className="image-uploader-container">
            <label className="image-upload-button">
              <input
                type="file"
                onChange={handleBulkUpload}
                style={{ display: "none" }}
                accept=".xlsx"
              />
              <div className="add-icon">+</div>
            </label>
          </div>

          {/* Preview section */}
          {fileName && (
            <div
              className="image-preview-container"
              style={{ marginTop: "20px" }}
            >
              <p>Uploaded file: {fileName}</p>
            </div>
          )}

          {formik.touched.bulkFile && formik.errors.bulkFile && (
            <div className="error">{formik.errors.bulkFile}</div>
          )}
        </div>

        <div className="buttons-container">
          <div className="button-group">
            <Button
              className="custom-white"
              label="Cancel"
              type="button"
              onClick={handleCloseDialog} // Close dialog with reset function
            />
          </div>

          <div className="button-group">
            <Button
              loading={loading}
              className="custom-btn"
              label="Submit"
              type="submit"
              onClick={formik.handleSubmit}
              disabled={loading}
            />
          </div>
        </div>
      </Dialog>

      <div className="grid align-items-center mb-3">
        <div className="col-12 md:col-2">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Search"
            />
          </span>
        </div>

        <div className="col-12 md:col-10 flex justify-content-end filter-responsive gap-3">
          {/* <Button
            className="custom-btn export-excel"
            label="Export to Excel"
            icon="pi pi-window-maximize"
            onClick={exportCSV}
          /> */}

          <Button
            label="Bulk Upload"
            className="custom-btn export-excel"
            icon="pi pi-window-maximize"
            onClick={(e) => {
              menuRef.current.toggle(e);
            }}
          />

          <Button
            className="custom-btn"
            label="Add New"
            icon="pi pi-plus"
            onClick={() => {
              setEditable(false);
              setDialog(true);
            }}
          />

          <Menu
            model={menuItems}
            popup
            className="custom-menu"
            ref={menuRef}
            onHide={() => setMenuVisible(false)}
          />
        </div>
      </div>

      <div className="card">
        {loading && <Loader />}

        <DataTable
          value={peopleList}
          scrollable
          scrollHeight="450px"
          filters={filters}
          paginator
          rows={10}
          ref={dt}
          globalFilterFields={[
            "name",
            "groupName",
            "mobileNumber",
            "gateNumber",
          ]}
          paginatorTemplate="CurrentPageReport RowsPerPageDropdown FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
          className="custom-data-table"
          expandedRows={expandedRows}
          onRowToggle={(e) => setExpandedRows(e.data)}
          rowExpansionTemplate={rowExpansionTemplate}
        >
          <Column expander style={{ width: "3em" }} />
          {columnsData.map((data, index) => (
            <Column
              key={index}
              field={data.field}
              header={data.header}
              body={data.body}
            />
          ))}
        </DataTable>
      </div>
    </>
  );
};

export default People;
